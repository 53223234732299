<template>
  <div>
    <iframe v-if="iframeSrc" :src="iframeSrc" :height="iframeHeight" />
    <div class="info-callout p-3">
      <div class="info-callout_image__container">
        <img src="/infoIcon.png" />
      </div>
      <div class="paragraph font-weight-bold">
        Ak vám nejde načítať dokument alebo kvíz, vyskúšajte niektoré z tipov:

        <ul>
          <li>Refresh stránky</li>
          <li>Prekliknutie sa na iné stránky a naspäť</li>
          <li>Odhlásenie/prihlásenie</li>
        </ul>

        V prípade pretrvávajúcich problémov nás kontaktuje na
        <a class="link" href="mailto:team@zmudri.sk"> team@zmudri.sk </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuizFormUrls } from "/utils/helpers";
import { get } from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    iframeSrc() {
      return getQuizFormUrls({ course: this.course, user: this.user })
        .iframeSrc;
    },
    iframeHeight() {
      const form = get(this.course, "attributes.quiz.data.attributes.form");
      return Number.parseFloat(form.embedHeight) + 100 || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-callout {
  font-family: "poppins";
  max-width: 467px;
  background: #fff5d1;
  border: 1px solid #e5d8a7;
  border-radius: 4px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;

  .info-callout_image__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 1;
  }

  img {
    max-width: 100%;
  }

  .paragraph {
    color: #998c5c;
    white-space: normal;
    margin: 0;

    a.link {
      font-weight: 700;
      color: inherit;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 20em;
    flex-direction: column;
    row-gap: 15px;
  }
}
</style>
